import Project from "../Project/Project";
import { WorkBody, WorkContainer } from "./WorkElements";
import { StyledHeading } from "../About/AboutElements";
import YT from "./../../Assets/youtube.mp4";
import Wiki from "./../../Assets/wiki.mp4";
import Blank from "./../../Assets/logo1.png";

const Work = () => {
  const discordTechnologies: string[] = [
    "React",
    "React Hooks",
    "SemanticUI",
    "CSS",
    "API Call",
    "npm",
    "Javascript",
    "Vercel"
  ];

  const algorithmVisualizationTechnologies: string[] = [
    "PHP",
    "Client-Server Model",
    "MySQL",
    "HTML",
    "Bootstrap",
  ];

  const sudokuTechnologies: string[] = [
    "React",
    "React Hooks",
    "SemanticUI",
    "CSS",
    "API Call",
    "npm",
    "Javascript",
    "Netlify"
  ];

  const minesweeperTechnologies: string[] = [
    "Python",
    "Matplotlib",
    "PDF Parser",
    "VS Code",
  ];

  return (
    <>
      <WorkBody id="work">
        <WorkContainer style={{ alignSelf: "flex-start" }}>
          <StyledHeading>Some things I've Built</StyledHeading>
        </WorkContainer>
        <div style={{ height: 40 }}></div>
        <Project
          invertedOrientation={false}
          projectName="YouTube Clone"
          // projectDescription="A fully functional Youtube clone implemented in React with the help of the React Hooks. The application uses YouTube V3 API to fetch first five videos based of the search keyword. The user can create an account or log in if an account already exists, add other users as friends, create groups, invite users to the group, add categories and channels to the groups, send personal and group messages, change personal and group profile photos, block users, change his activity status and see friends activity status."
          projectDescription="A fully functional YouTube clone implemented in React with the help of the React Hooks. The application uses YouTube V3 API to fetch videos based of the search keyword."
          video={YT}
          stackItems={discordTechnologies}
        ></Project>
        <Project
          invertedOrientation={true}
          projectName="PHP Website"
          projectDescription="Algorithm visualization illustrates how algorithms work in a graphical way. It aims to simplify and deepen the understanding of the algorithm's operation. Currently, two algorithms are implemented: Breadth-First Search and A-Star. The application allows the user to draw 'walls', set starting and ending points, and reset the board."
          video={Blank}
          stackItems={algorithmVisualizationTechnologies}
        ></Project>
        <Project
          invertedOrientation={false}
          projectName="Search Wikipedia"
          // projectDescription="The simple Search Wikipedia implemented in React with the help of the React Hooks.The application uses YouTube V3 API to fetch videos based of the search keyword. The application implements the Remote Method Invocation model. The user can use both the mouse and the keyboard for input, choose the difficulty level, take notes, undo and redo moves and request a solution to the puzzle from the server."
          projectDescription="The simple Search Wikipedia implemented in React with the help of the React Hooks.The application uses YouTube V3 API to fetch videos based of the search keyword."
          video={Wiki}
          stackItems={sudokuTechnologies}
        ></Project>
        <Project
          invertedOrientation={true}
          projectName="Discord Gym Bot"
          // projectDescription="The Windows XP version of the Minesweeper implemented in PyGame. The mines are randomly placed and then the whole board is filled with unflipped blocks. The user can reset the board at any time by pressing the emoji button."
          projectDescription="The Gym Bot is implemented in Python. It shows the weekly gym schedule when a command is given and also highloghtes today's workout. At the end of month user can upload gym log pdf and bot will display a very simple graph with how many days user went to gym and how many days were missed to keep track of monthly progress."
          video={Blank}
          stackItems={minesweeperTechnologies}
        ></Project>
        <h1 style={{ color: "white", paddingBottom: "1.2em" }}>
          You can check more of my work in&nbsp;
          <a
            style={{ color: "white", alignSelf: "center" }}
            href="https://github.com/shahidnawaz2528"
          >
            Github
          </a>
        </h1>
      </WorkBody>
    </>
  );
};

export default Work;
