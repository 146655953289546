import { StyledHeading } from "../About/AboutElements";
import RotatingSphere from "../RotatingSphere/RotatingSphere";
import { SkillsBody, SkillsContainer, Text } from "./SkillsElements";

const Skills = () => {
  return (
    <>
      <SkillsBody id="skills">
        <SkillsContainer>
          <StyledHeading>Skills</StyledHeading>
          <Text>
            I have been coding for 4 years and I have learned to code in C++,
            Python, Java, JavaScript, database
            management with MySQL and MongoDB. I possess strong knowledge in Data
            Structures, Algorithms, and OOP principles. I can set up and manage
            a Windows OS, Linux OS, and Mac OS.
            <br />
            <br />I have taken multiple courses and have base
            knowledge with different Cloud Services like Microsoft Azure, Amazon AWS, and
             a VPS. I also have strong knowledge of Github, dealing with branches and PR.
            You can check my&nbsp;
            <a
              style={{ color: "white" }}
              href="https://www.linkedin.com/in/shahid-nawaz-712194196/"
            >
              LinkedIn
            </a>{" "}
            profile here.
          </Text>
        </SkillsContainer>
        <SkillsContainer justify="center">
          <RotatingSphere />
        </SkillsContainer>
      </SkillsBody>
    </>
  );
};

export default Skills;
